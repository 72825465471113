import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/UserRankings.css';

const UserRankings = () => {
  const [rankings, setRankings] = useState([]);
  const [error, setError] = useState(null);
  const { web3, contract, allUsers, setAllusers, timeDateContract, usdtDecimal } = useContext(WalletContext);

  useEffect(() => {
    const fetchRankings = async () => {
      if (!web3 || !contract) {
        setError('Web3 or contract not initialized');
        return;
      }

      try {
        const userCommissions = await Promise.all(
          allUsers.map(async (user) => {
            const commission = await contract.methods.getMilestonAmount(user).call();
            return { address: user, commission: commission.toString() };
          })
        );

        const sortedRankings = userCommissions
          .sort((a, b) => (b.commission > a.commission ? 1 : -1))
          .map((user, index) => ({ ...user, rank: index + 1 }));

        setRankings(sortedRankings);
        setError(null);
      } catch (err) {
        console.error('Error fetching rankings:', err);
        setError(err.message || 'An error occurred while fetching rankings');
      }
    };

    fetchRankings();
  }, [web3, contract, allUsers]);

  useEffect(() => {
    if (timeDateContract) {
      fetchTransactions();
    }
  }, [timeDateContract]);

  const fetchTransactions = async () => {
    try {
      const taxCount = await timeDateContract.methods.taxCount().call();
      console.log('Tax count:', taxCount);
      
      const uniqueAddresses = new Set();

      for (let i = taxCount; i >= 1; i--) {
        const taxData = await timeDateContract.methods.getTaxData(i).call();
        // console.log(`Transaction ${i} data:`, taxData);
        // console.log(`Transaction ${i} type (taxData[8]):`, taxData[8]);
        if (taxData[0] !== 'TULJq3p6YaTxTCBeWhFRkQ1pfS6ocLinTU') {
          uniqueAddresses.add(taxData[0]);
        }
      }

      const allUserAddress = Array.from(uniqueAddresses);
      setAllusers(allUserAddress);

      console.log('All Unique Users:', allUsers);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const getRankTag = (rank) => {
    switch (rank) {
      case 1:
        return <span className="rank-tag gold">🥇</span>;
      case 2:
        return <span className="rank-tag silver">🥈</span>;
      case 3:
        return <span className="rank-tag bronze">🥉</span>;
      default:
        return <span className="rank-tag">{rank}th</span>;
    }
  };

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="user-rankings-container">
      <h2>User Rankings</h2>
      {rankings.length > 0 ? (
        <table className="rankings-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>User Address</th>
              <th>Commission Earned</th>
            </tr>
          </thead>
          <tbody>
            {rankings.map((user) => (
              <tr key={user.address} className={user.rank <= 3 ? `top-${user.rank}` : ''}>
                <td>{getRankTag(user.rank)}</td>
                <td>{`${user.address.slice(0, 6)}...${user.address.slice(-4)}`}</td>
                <td>$ {parseFloat(user.commission.toString() / (10 ** usdtDecimal)).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="loading-message">Loading rankings...</div>
      )}
    </div>
  );
};

export default UserRankings;