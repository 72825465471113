// components/Header.js
import { React, useEffect, useContext, useCallback } from 'react';
import Web3 from 'web3';
import ContractABI from '../ABI/MasterABI.json';
import TokenABI from '../ABI/TokenABI.json';
import TimeDateABI from '../ABI/TimeABI.json';
import MerchantABI from '../ABI/MerchantABI.json';
import StakingABI from '../ABI/StakingABI.json';
import { WalletContext } from '../WalletContext';
import '../styles/Header.css';
import logo from '../assets/img/logo/logo.png';

const Header = () => {
  const {
    referee, setReferee, setIsSuperAdmin,
    account, setAccount, isSuperAdmin,
    isConnected, setIsConnected,
    web3, setWeb3, contract, setContract,
    setApproved, bitcoinExplorerMember,
    setBitcoinExplorerMember, blockchainProMember,
    setBlockchainProMember, metaverseMasterMember, setMetaverseMasterMember,
    aiUltimateProMember, setAiUltimateProMember,
    masterAddress, setMasterAddress, timeDateContract, setJoiningDate,
    tokenAddress, setTokenAddress, setUsdtDecimal,
    stakingAddress, setStakingAddress, setMerchantContract,
    stakingContract, setStakingContract, merchantAddress, setMerchantAddress,
    usdtContract, setUsdtContract, setMilestonRewards,
    setTotalRefferals, connectedChainId, setConnectedChainId,
    setThisMonthEarning, setTodaysEarning, setTotalEarning,
    setThisYearEarning, setTimeDateAddress, setTimeDateContract, timeDateAddress
  } = useContext(WalletContext);
  
  const tokenContractAddress = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
  const masterContractAddress = '0x6ccB96E42cB8470cD62Be22E461c1fC6bE5b63a4';
  const dataLibraryContractAddress = '0x2eEf614A146190560de9719c3A2a78690EF87Df7';
  const stakingContractAddress = '0x056EcB0f7525b288f5087069eAa21A56a168D679';
  const merchantContractAddress = '0xb932d3e22e665Cf4eEb048ee5878AfCF7DB3d08e';
  const merchantLibraryContractAddress = '0xb932d3e22e665Cf4eEb048ee5878AfCF7DB3d08e';
  
  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    } else if (window.web3) {
      const web3Instance = new Web3(window.web3.currentProvider);
      setWeb3(web3Instance);
    } else {
      console.error('No Web3 provider detected');
    }
    setMasterAddress(masterContractAddress);
    setTokenAddress(tokenContractAddress);
    setStakingAddress(stakingContractAddress);
    setTimeDateAddress(dataLibraryContractAddress);
    setMerchantAddress(merchantContractAddress);
  }, [setWeb3, setMasterAddress, setTokenAddress, setStakingAddress, setTimeDateAddress]);
  
  async function getConnectedChainId() {
    if (!isConnected || !account) {
      console.log('Not connected with web3.');
      return;
    }
    try {
      const _chainId = await web3.eth.getChainId();
      const chainId = Number(_chainId);
      setConnectedChainId(chainId);
      // console.log('Chain Id', chainId);
      return chainId;
    } catch (error) {
      console.error('Error getting connected chain ID:', error);
      return null;
    }
  }
  
  async function switchToPolygonMainnet() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }],
      });
    } catch (error) {
      if (error.code === 4902) {
        // If the chain is not added, add it
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x89',
              chainName: 'Polygon Mainnet',
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: ['https://polygon-rpc.com/'],
              blockExplorerUrls: ['https://polygonscan.com/'],
            },
          ],
        });
      } else {
        console.error('Error switching to Polygon Mainnet:', error);
      }
    }
  }
  
  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    const referralIndex = pathParts.indexOf('referral');

    if (referralIndex !== -1 && pathParts.length > referralIndex + 1) {
      const referralAddress = pathParts[referralIndex + 1];
      setReferee(referralAddress);
      // console.log(`Referred by:`, referralAddress);
    } else {
      setReferee('0xeE2446E45a9AA2ba8979a4406F521f2C5Cf7b550');
    }
    // console.log("Referraed by:", referee);
    getConnectedChainId();

    async function checkAndSwitchNetwork() {
      if (connectedChainId !== 137) {
        // Chain is not Polygon Mainnet, switch the network
        await switchToPolygonMainnet();
      }
    }
    checkAndSwitchNetwork();
  }, [setReferee, getConnectedChainId, referee]);

  const updateData = useCallback(async () => {
    if (!isConnected || !timeDateContract || !account) {
      console.log('Library or account not available');
      return;
    }
    try {
      const getTaxsNumbers = await timeDateContract.methods.getTaxNumbers(account).call();
      const taxNumber = Number(getTaxsNumbers[0]);
      if (taxNumber > 0) {
        const getData = await timeDateContract.methods.getTaxData(taxNumber).call();
        const theDate = `${Number(getData.day)}/${Number(getData.month)}/${Number(getData.year)}`;
        setJoiningDate(theDate);
      } else {
        // console.log('No transection found');
        setJoiningDate('');
      }
      // console.log('Tax Data', theDate);
    } catch (error) {
      console.error('Error to update data:', error);
    }
  }, [isConnected, timeDateContract, account, setJoiningDate]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    const intervalId = setInterval(() => {
      updateData();
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, [updateData]);

  useEffect(() => {
    if (web3 && masterAddress && tokenAddress && stakingAddress && account && timeDateAddress) {
      const contractInstance = new web3.eth.Contract(ContractABI, masterAddress);
      setContract(contractInstance);
      const usdtContractInstance = new web3.eth.Contract(TokenABI, tokenAddress);
      setUsdtContract(usdtContractInstance);
      const timeDateContractInstance = new web3.eth.Contract(TimeDateABI, timeDateAddress);
      setTimeDateContract(timeDateContractInstance);
      const merchantContractInstance = new web3.eth.Contract(MerchantABI, merchantAddress);
      setMerchantContract(merchantContractInstance);
      const stakingContractInstance = new web3.eth.Contract(StakingABI, stakingContractAddress);
      setStakingContract(stakingContractInstance);
    }
  }, [account, timeDateAddress, web3, masterAddress, tokenAddress, stakingAddress, setContract, setStakingContract, setUsdtContract, setTimeDateContract]);

  // The function to update USDT decimals
  const updateDecimals = useCallback(async () => {
    if (!isConnected || !usdtContract) {
      console.log('User not connected.');
      return;
    }
    try {
      // console.log('Updating decimal.');
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      // console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
    } catch (error) {
      console.error('Error updating usdt decimals', error);
    }
  }, [isConnected, usdtContract, setUsdtDecimal]);

  const updateReferral = useCallback(async () => {
    if (!contract || !account) {
      console.log('Contract or account not available');
      return;
    }
    try {
      console.log("Updating referrals...");
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      // console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
      const _totalRefferals = await contract.methods.getReferrerLenth(account).call();
      const _todysEarning = await contract.methods.getTodaysEarning(account).call();
      const thisMonth = await contract.methods.getThisMonthEarning(account).call();
      const thisYear = await contract.methods.getThisYearEarning(account).call();
      const earningTotal = await contract.methods.getTotalEarning(account).call();
      const referalsTotal = _totalRefferals.toString();
      const erningTodays = _todysEarning.toString() / (10 ** decimalNumber);
      const erningThisMonth = thisMonth.toString() / (10 ** decimalNumber);
      const erningThisYear = thisYear.toString() / (10 ** decimalNumber);
      const total_Erning = earningTotal.toString() / (10 ** decimalNumber);
      setTotalRefferals(referalsTotal);
      setTodaysEarning(erningTodays.toFixed(2));
      setThisMonthEarning(erningThisMonth.toFixed(2));
      setThisYearEarning(erningThisYear.toFixed(2));
      setTotalEarning(total_Erning.toFixed(2));
      // console.log("Updating referrals sucsessfuly.", erningTodays, erningThisMonth, erningThisYear, total_Erning);
    } catch (error) {
      console.error("Error updating referrals", error);
    }
  }, [contract, account, setThisMonthEarning, setThisYearEarning, setTodaysEarning, setTotalEarning, setTotalRefferals]);

  const updateMilestoneReward = useCallback(async () => {
    // console.log("Updating milestone reward...");
    if (!contract || !account) {
      console.log('Contract or account not available');
      return;
    }
    try {
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      // console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
      const rewardsAmount = await contract.methods.getMilestonAmount(account).call();
      // console.log("Raw milestone amount:", rewardsAmount);
      const rewardsAmountNumber = Number(rewardsAmount.toString());
      const amount = (rewardsAmountNumber / (10 ** decimalNumber)).toFixed(2);
      // console.log("Formatted milestone amount:", amount);
      setMilestonRewards(amount);
    } catch (error) {
      console.error("Error updating milestone amount", error);
      // console.log("Contract address:", contract._address);
      // console.log("Account:", account);
    }
  }, [contract, account, setMilestonRewards]);

  // Initialize and update all necessary data
  useEffect(() => {
    if (!isConnected) return;
    const initializeData = async () => {
      await updateDecimals();
      await updateMilestoneReward();
      await updateReferral();
    };
    initializeData();
  }, [isConnected, updateDecimals, updateMilestoneReward, updateReferral]);

  const checkApprovalStatus = useCallback(async () => {
    if (!usdtContract || !account || !masterAddress) {
      // console.log('usdtContract, account, or masterAddress not available');
      return false;
    }
    try {
      const allowance = await usdtContract.methods.allowance(account, masterAddress).call();
      return allowance > 0;
    } catch (error) {
      console.error('Error checking approval status:', error);
      return false;
    }
  }, [usdtContract, account, masterAddress]);

  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3Instance = new Web3(window.ethereum);
      const accounts = await web3Instance.eth.getAccounts();
      const userAddress = accounts[0];
      const contract = new web3Instance.eth.Contract(StakingABI, stakingContractAddress);
      const contractOwner = await contract.methods.owner().call();
      const isAdminUser = await contract.methods.getRollByAdmin(userAddress);
      console.log('Is admin user:', isAdminUser[1]);
      if (!isAdminUser[1] && contractOwner !== userAddress) {
        alert('Only admin can access! Sorry!');
        return;
      }
      console.log('Set super admin', contractOwner, userAddress);
      if (contractOwner === userAddress) {
        setIsSuperAdmin(true);
        console.log('Set super admin', userAddress);
      }
      setWeb3(web3Instance);
      setAccount(userAddress);
      setIsConnected(true);
      const usdtApproved = await checkApprovalStatus();
      await updateMilestoneReward();
      setApproved(usdtApproved);
      checkMembership();
      updateData();
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  const checkMembership = useCallback(async () => {
    if (!stakingContract || !account) {
      console.log('stakingContract or account not available');
      return;
    }
    try {
      console.log(`Checking membership for account: ${account}`);

      // Get all owned tokens for the account
      const ownedTokens = await stakingContract.methods.getOwnedTokens(account).call();
      console.log(`Owned Tokens: ${ownedTokens}`);

      let bitcoinExplorer = 0;
      let blockchainPro = 0;
      let metaverseMaster = 0;
      let aiUltimatePro = 0;

      // Iterate through owned tokens
      for (let tokenId of ownedTokens) {
        const character = await stakingContract.methods.characterOf(tokenId).call();

        switch (parseInt(character)) {
          case 1:
            bitcoinExplorer++;
            break;
          case 2:
            blockchainPro++;
            break;
          case 3:
            metaverseMaster++;
            break;
          case 4:
            aiUltimatePro++;
            break;
          default:
            console.warn(`Unknown character type for token ${tokenId}: ${character}`);
        }
      }

      console.log(`Bitcoin Explorer: ${bitcoinExplorer}`);
      console.log(`Blockchain Pro: ${blockchainPro}`);
      console.log(`Metaverse Master: ${metaverseMaster}`);
      console.log(`AI Ultimate Pro: ${aiUltimatePro}`);

      setBitcoinExplorerMember(bitcoinExplorer);
      setBlockchainProMember(blockchainPro);
      setMetaverseMasterMember(metaverseMaster);
      setAiUltimateProMember(aiUltimatePro);

    } catch (error) {
      console.error('Error checking membership:', error);
    }
  }, [stakingContract, account, setBitcoinExplorerMember, setBlockchainProMember, setMetaverseMasterMember, setAiUltimateProMember]);

  useEffect(() => {
    if (isConnected && web3 && stakingContract && account) {
      checkMembership();
    }
  }, [isConnected, web3, stakingContract, account, checkMembership]);

  useEffect(() => {
    if (isConnected && contract && account) {
      updateMilestoneReward();
      updateReferral();
    }
  }, [isConnected, contract, account, updateMilestoneReward, updateReferral]);

  useEffect(() => {
    if (isConnected) {
      console.log('Account Address:', account);
      console.log('BitcoinExplorerMembership:', bitcoinExplorerMember);
      console.log('BlockchainProMembership:', blockchainProMember);
      console.log('MetaverseMasterMembership:', metaverseMasterMember);
      console.log('AiUltimateProMembership:', aiUltimateProMember);
    }
  }, [isConnected, account, bitcoinExplorerMember, blockchainProMember, metaverseMasterMember, aiUltimateProMember]);

  const disconnectWallet = () => {
    setAccount('');
    setIsConnected(false);
    setBitcoinExplorerMember(0);
    setBlockchainProMember(0);
    setMetaverseMasterMember(0);
    setAiUltimateProMember(0);
    setApproved(false);
  };

  return (
    <div className="header">
      <div className="header-left">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {isConnected ? (
        <div className="header-right">
          <button className="logout-btn" onClick={disconnectWallet}><span class="glyphicon log-out glyphicon-log-out"> </span>
            Logout
          </button>
        </div>
      ) : (
        <div className="header-right">
          <button className="connect-btn" onClick={connectWallet}> <span className='glyphicon log-in glyphicon-log-in'></span>
            Connect Wallet
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;