import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/TransactionTable.css';

const TransactionTable = () => {
  const { usdtDecimal, timeDateContract, allUsers, setAllusers, transactions, setTransactions } = useContext(WalletContext);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filters, setFilters] = useState({
    address: '',
    commission: '',
    type: '',
    date: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (timeDateContract) {
      fetchTransactions();
    }
  }, [timeDateContract]);

  useEffect(() => {
    applyFilters();
  }, [transactions, filters]);

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      const taxCount = await timeDateContract.methods.taxCount().call();
      console.log('Tax count:', taxCount);

      const fetchedTransactions = [];
      const uniqueAddresses = new Set();

      for (let i = taxCount; i >= 1; i--) {
        const taxData = await timeDateContract.methods.getTaxData(i).call();
        console.log(`Transaction ${i} data:`, taxData);
        console.log(`Transaction ${i} type (taxData[8]):`, taxData[8]);

        const typeNumber = String(taxData[8]);
        console.log('Types', typeNumber);

        let type;
        if (typeNumber === '1') {
          type = 'Purchase';
        } else if (typeNumber === '2') {
          type = 'Referral';
        } else if (typeNumber === '3') {
          type = 'Withdraw';
        } else if (typeNumber === '4') {
          type = 'Commission';
        } else if (typeNumber === '5') {
          type = 'Business Rewards';
        } else if (typeNumber === '6') {
          type = 'Rewards Claim';
        } else if (typeNumber === '7') {
          type = 'Reinvest Pen.. Rew..';
        } else if (typeNumber === '8') {
          type = 'Reinvest Commission';
        } else {
          type = 'Unknown';
        }
        console.log(`Interpreted type for transaction ${i}:`, type);

        uniqueAddresses.add(taxData[0]);

        const amountnumber = Number(taxData[1])

        if (taxData[0] !== 'TULJq3p6YaTxTCBeWhFRkQ1pfS6ocLinTU') {
          fetchedTransactions.push({
            address: taxData[0],
            amount: amountnumber / 10 ** usdtDecimal,
            year: String(taxData[2]),
            month: String(taxData[3]),
            day: String(taxData[4]),
            hour: String(taxData[5]),
            minute: String(taxData[6]),
            second: String(taxData[7]),
            type: type,
          });
        }
      }

      const allUserAddress = Array.from(uniqueAddresses);
      setAllusers(allUserAddress);

      console.log('All Unique Users:', allUsers);
      console.log('All fetched transactions:', fetchedTransactions);
      setTransactions(fetchedTransactions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    let filtered = transactions;

    if (filters.address) {
      filtered = filtered.filter(tx =>
        tx.address.toLowerCase().includes(filters.address.toLowerCase())
      );
    }
    if (filters.commission) {
      filtered = filtered.filter(tx =>
        tx.amount.includes(filters.commission)
      );
    }
    if (filters.type) {
      filtered = filtered.filter(tx =>
        tx.type.toLowerCase() === filters.type.toLowerCase()
      );
    }
    if (filters.date) {
      filtered = filtered.filter(tx =>
        `${tx.year}-${tx.month.padStart(2, '0')}-${tx.day.padStart(2, '0')}` === filters.date
      );
    }

    setFilteredTransactions(filtered);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="transaction-table">
      <div className="filters">
        <input
          name="address"
          placeholder="Find by Address"
          value={filters.address}
          onChange={handleFilterChange}
        />
        <input
          name="commission"
          placeholder="Find by Amount"
          value={filters.commission}
          onChange={handleFilterChange}
        />
        <select
          className='select-dropdown'
          name="type"
          value={filters.type}
          onChange={handleFilterChange}
        >
          <option value="">All Types</option>
          <option value="Purchase">Purchase</option>
          <option value="Referral">Referral</option>
        </select>
        <input
          type="date"
          name="date"
          value={filters.date}
          onChange={handleFilterChange}
        />
      </div>
      {isLoading ? (
        <div className="loading-message">Loading transactions...</div>
      ) : filteredTransactions.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>SL</th>
              <th>Address</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Date (UTC)</th>
              <th>Time (UTC)</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((tx, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{tx.address.slice(0, 6)}...{tx.address.slice(-4)}</td>
                <td>${parseFloat(tx.amount).toFixed(2)}</td>
                <td>{tx.type}</td>
                <td>{`${tx.day.padStart(2, '0')}-${tx.month.padStart(2, '0')}-${tx.year}`}</td>
                <td>{`${tx.hour.padStart(2, '0')}:${tx.minute.padStart(2, '0')}:${tx.second.padStart(2, '0')}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-data-message">No transactions found.</div>
      )}
    </div>
  );
};

export default TransactionTable;