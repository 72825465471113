// WalletContext.js
import { React, createContext, useState } from 'react';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [account, setAccount] = useState('');
  const [isConnected, setIsConnected] = useState('');
  const [web3, setWeb3] = useState('');
  const [isApproved, setApproved] = useState(false);
  const [contract, setContract] = useState('');
  const [bitcoinExplorerMember, setBitcoinExplorerMember] = useState('');
  const [blockchainProMember, setBlockchainProMember] = useState('');
  const [metaverseMasterMember, setMetaverseMasterMember] = useState('');
  const [aiUltimateProMember, setAiUltimateProMember] = useState('');
  const [stakingContract, setStakingContract] = useState('');
  const [usdtContract, setUsdtContract] = useState('');
  const [referee, setReferee] = useState('');
  const [milestonRewards, setMilestonRewards] = useState('');
  const [totalRefferals, setTotalRefferals] = useState('');
  const [totalEarning, setTotalEarning] = useState('');
  const [todaysEarning, setTodaysEarning] = useState('');
  const [thisMonthEarning, setThisMonthEarning] = useState('');
  const [thisYearEarning, setThisYearEarning] = useState('');
  const [itemSelected, setItemSelected] = useState('');
  const [timeDateAddress, setTimeDateAddress] = useState('');
  const [masterAddress, setMasterAddress] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [stakingAddress, setStakingAddress] = useState('');
  const [timeDateContract, setTimeDateContract] = useState('');
  const [allUsers, setAllusers] = useState([]);
  const [todaysSell, setTodaysSell] = useState('');
  const [thisMonthSell, setThisMonthSell] = useState('');
  const [thisYearSell, setThisYearSell] = useState('');
  const [connectedChainId, setConnectedChainId] = useState();
  const [usdtDecimal, setUsdtDecimal] = useState();
  const [merchantAddress, setMerchantAddress] = useState('');
  const [merchantContract, setMerchantContract] = useState('');
  const [joiningDate, setJoiningDate] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <WalletContext.Provider value={{
      todaysSell, setTodaysSell, thisMonthSell, setThisMonthSell, thisYearSell, setThisYearSell,
      account, setAccount, referee, setReferee, isConnected, setIsConnected,
      web3, setWeb3, contract, setContract, merchantAddress, setMerchantAddress,
      bitcoinExplorerMember, setBitcoinExplorerMember,
      blockchainProMember, setBlockchainProMember, merchantContract, setMerchantContract,
      metaverseMasterMember, setMetaverseMasterMember,
      aiUltimateProMember, setAiUltimateProMember,
      isApproved, setApproved, connectedChainId, setConnectedChainId,
      masterAddress, setMasterAddress, joiningDate, setJoiningDate,
      tokenAddress, setTokenAddress, usdtDecimal, setUsdtDecimal,
      stakingAddress, setStakingAddress,
      stakingContract, setStakingContract, transactions, setTransactions,
      usdtContract, setUsdtContract, isSuperAdmin, setIsSuperAdmin,
      milestonRewards, setMilestonRewards,
      totalRefferals, setTotalRefferals, isAdmin, setIsAdmin,
      thisMonthEarning, setThisMonthEarning,
      todaysEarning, setTodaysEarning, totalEarning, setTotalEarning,
      thisYearEarning, setThisYearEarning, itemSelected, setItemSelected,
      timeDateAddress, setTimeDateAddress, timeDateContract, setTimeDateContract,
      allUsers, setAllusers
    }}>
      {children}
    </WalletContext.Provider>
  );
};