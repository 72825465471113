import React, { useContext, useState, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/AdminApplication.css';

const AdminApplication = () => {
  const {
    merchantContract,
    isConnected,
    account,
    setItemSelected,
    itemSelected
  } = useContext(WalletContext);

  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [editDetails, setEditDetails] = useState({});
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (merchantContract && isConnected) {
      fetchApplications();
    }
  }, [merchantContract, isConnected]);

  const fetchApplications = async () => {
    try {
      setError('');
      if (!merchantContract.methods || !merchantContract.methods.applicationNonce) {
        throw new Error('merchantContract.methods.applicationNonce is not available');
      }
      const applicationNonce = await merchantContract.methods.applicationNonce().call();
      console.log('Application Nonce', Number(applicationNonce));
      const apps = [];
      for (let i = 78692; i <= applicationNonce; i++) {
        if (!merchantContract.methods.getApplicantDetails) {
          throw new Error('merchantContract.methods.getApplicantDetails is not available');
        }
        const app = await merchantContract.methods.getApplicantDetails(i).call();
        console.log(`Application ${i}:`, app);
        if (app.seller !== '0x0000000000000000000000000000000000000000') {
          const processedApp = {
            ...app,
            nonce: i,
            pinCode: app.pinCode ? app.pinCode.toString() : '',
            email: app.email || 'Email not provided'
          };
          apps.push(processedApp);
        }
      }
      setApplications(apps);
      console.log('Fetched applications:', apps);
    } catch (error) {
      console.error('Error fetching applications:', error);
      setError(`Error fetching applications: ${error.message}`);
    }
  };

  const handleApplicationSelect = (nonce) => {
    console.log('Selected nonce:', nonce);
    const selected = applications.find(app => app.nonce === nonce);
    console.log('Selected application:', selected);
    setSelectedApplication(selected);
    setEditDetails(selected);
    setItemSelected(nonce);
  };

  const handleApprove = async () => {
    try {
      setError('');
      if (!merchantContract.methods || !merchantContract.methods.updateApplication) {
        throw new Error('merchantContract.methods.updateApplication is not available');
      }
      console.log('Approving application with nonce:', selectedApplication.nonce);
      if (editDetails.nonce == 0) {
        editDetails.nonce = selectedApplication.nonce;
      }
      await merchantContract.methods.updateApplication(editDetails, selectedApplication.nonce, true).send({ from: account });
      fetchApplications();
    } catch (error) {
      console.error('Error approving application:', error);
      setError(`Error approving application: ${error.message}`);
    }
  };

  const handleReject = async () => {
    try {
      setError('');
      if (!merchantContract.methods || !merchantContract.methods.updateApplication) {
        throw new Error('merchantContract.methods.updateApplication is not available');
      }
      console.log('Rejecting application with nonce:', selectedApplication.nonce);
      if (editDetails.nonce == 0) {
        editDetails.nonce = selectedApplication.nonce;
      }
      await merchantContract.methods.updateApplication(editDetails, selectedApplication.nonce, false).send({ from: account });
      fetchApplications();
    } catch (error) {
      console.error('Error rejecting application:', error);
      setError(`Error rejecting application: ${error.message}`);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditDetails(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="form-container">
      <h1 className="heading">Merchant Application Details</h1>

      {/* {error && <div className="error-message">{error}</div>} */}

      {applications.length > 0 ? (
        <div className="formGroup">
          <label className="label" htmlFor="application-select">Select Application:</label>
          <select
            id="application-select"
            className="select"
            onChange={(e) => handleApplicationSelect(Number(e.target.value))}
            value={selectedApplication ? selectedApplication.nonce : ''}
          >
            <option value="">--Select an Application--</option>
            {applications.map((app) => (
              <option key={app.nonce} value={app.nonce}>
                Registration Number: {app.nonce} - Shop Name: {app.shopName}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <p>No applications available.</p>
      )}
      
      {selectedApplication && (
        <div className="form">
          <div className="formGroup">
            <label className="label">Application Nonce:</label>
            <input
              type="text"
              className="input"
              value={selectedApplication.nonce}
              readOnly
            />
            <label className="label">Shop/Business Name:</label>
            <input
              type="text"
              name="shopName"
              className="input"
              value={editDetails.shopName || ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">Categories:</label>
            <input
              type="text"
              name="categories"
              className="input"
              value={editDetails.categories ? editDetails.categories.join(', ') : ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">Country:</label>
            <input
              type="text"
              name="country"
              className="input"
              value={editDetails.country || ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">State/Province:</label>
            <input
              type="text"
              name="state"
              className="input"
              value={editDetails.state || ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">Pin Code:</label>
            <input
              type="text"
              name="pinCode"
              className="input"
              value={editDetails.pinCode || ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">Business Address:</label>
            <textarea
              name="shopAddress"
              className="textarea"
              value={editDetails.shopAddress || ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">Email:</label>
            <input
              type="email"
              name="email"
              className="input"
              value={editDetails.email || 'Email not provided'}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">Refund Policy:</label>
            <textarea
              name="refundPolicy"
              className="textarea"
              value={editDetails.refundPolicy || ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="formGroup">
            <label className="label">Privacy Policy:</label>
            <textarea
              name="privacyPolicy"
              className="textarea"
              value={editDetails.privacyPolicy || ''}
              onChange={handleEditChange}
              readOnly
            />
          </div>

          <div className="application-actions">
            <button className="button" onClick={() => setStatus('approve')}>Approve</button>
            <button className="removeButton" onClick={() => setStatus('reject')}>Reject</button>
          </div>
        </div>
      )}

      {status && (
        <div className="application-confirmation">
          <p>Are you sure you want to {status} this application (Nonce: {selectedApplication.nonce})?</p>
          <button className="button" onClick={status === 'approve' ? handleApprove : handleReject}>
            Confirm {status}
          </button>
          <button className="removeButton" onClick={() => setStatus('')}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default AdminApplication;