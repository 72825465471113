import React, { useState, useEffect, useContext } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatEther } from 'ethers';
import { WalletContext } from '../WalletContext';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const { web3, contract, allUsers, usdtDecimal, setAllusers, timeDateContract, setTransactions } = useContext(WalletContext);

  useEffect(() => {
    const fetchData = async () => {
      if (web3 && contract && allUsers.length > 0) {
        try {
          setLoading(true);
          setLoadingProgress(0);

          let todaysSell = 0;
          let thisMonthSell = 0;
          let thisYearSell = 0;
          let overallSell = 0;
          let todaysUsers = 0;
          let thisMonthUsers = 0;
          let thisYearUsers = 0;
          const totalUsers = allUsers.length;

          console.log('going to fetch all user data');

          for (let i = 0; i < allUsers.length; i++) {
            try {
              const _todaysSell = await contract.methods.getTodaysEarning(allUsers[i]).call();
              const _thisMonthSell = await contract.methods.getThisMonthEarning(allUsers[i]).call();
              const _thisYearSell = await contract.methods.getThisYearEarning(allUsers[i]).call();
              
              todaysSell += parseInt(_todaysSell) / 10 ** usdtDecimal;
              thisMonthSell += parseInt(_thisMonthSell) / 10 ** usdtDecimal;
              thisYearSell += parseInt(_thisYearSell) / 10 ** usdtDecimal;
              
              if (parseInt(_todaysSell) > 0) todaysUsers++;
              if (parseInt(_thisMonthSell) > 0) thisMonthUsers++;
              if (parseInt(_thisYearSell) > 0) thisYearUsers++;
            } catch (err) {
              console.error(`Error fetching data for user ${allUsers[i]}:`, err);
            }

            // Update loading progress
            setLoadingProgress((i + 1) / allUsers.length * 100);
          }
          
          overallSell = todaysSell + thisMonthSell + thisYearSell;

          // Aggregate data
          const aggregatedData = [
            { period: 'Today', users: todaysUsers, totalAmount: todaysSell },
            { period: 'This Month', users: thisMonthUsers, totalAmount: thisMonthSell },
            { period: 'This Year', users: thisYearUsers, totalAmount: thisYearSell },
            { period: 'Overall', users: totalUsers, totalAmount: overallSell },
          ];

          console.log('Aggregate data', todaysSell, thisMonthSell, thisYearSell, overallSell);

          setChartData(aggregatedData);
          setError(null);
          setLoading(false);
          setLoadingProgress(0);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError(error.message);
          setLoading(false);
          setLoadingProgress(0);
        }
      }
    };

    fetchData();
  }, [web3, contract, allUsers]);

  useEffect(() => {
    if (timeDateContract && (allUsers.length === 0 || !allUsers)) {
      fetchTransactions();
    }
  }, [timeDateContract, allUsers]);

  const fetchTransactions = async () => {
    try {
      const taxCount = await timeDateContract.methods.taxCount().call();
      console.log('Tax count:', taxCount);

      const fetchedTransactions = [];
      const uniqueAddresses = new Set();

      for (let i = taxCount; i >= 1; i--) {
        const taxData = await timeDateContract.methods.getTaxData(i).call();
        console.log(`Transaction ${i} data:`, taxData);
        console.log(`Transaction ${i} type (taxData[8]):`, taxData[8]);

        const typeNumber = String(taxData[8]);
        console.log('Types', typeNumber);

        let type;
        if (typeNumber === '1') {
          type = 'Purchase';
        } else if (typeNumber === '2') {
          type = 'Referral';
        } else {
          type = 'Unknown';
        }
        console.log(`Interpreted type for transaction ${i}:`, type);

        uniqueAddresses.add(taxData[0]);

        const amountnumber = Number(taxData[1])
        
        if (taxData[0] !== 'TULJq3p6YaTxTCBeWhFRkQ1pfS6ocLinTU') {
          fetchedTransactions.push({
            address: taxData[0],
            amount: amountnumber / 10 ** usdtDecimal,
            year: String(taxData[2]),
            month: String(taxData[3]),
            day: String(taxData[4]),
            hour: String(taxData[5]),
            minute: String(taxData[6]),
            second: String(taxData[7]),
            type: type,
          });
        }
      }

      const allUserAddress = Array.from(uniqueAddresses);
      setAllusers(allUserAddress);

      console.log('All Unique Users:', allUsers);
      console.log('All fetched transactions:', fetchedTransactions);
      setTransactions(fetchedTransactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="dashboard-container">
      {loading ? (
        <div className="loading-container">
          <div className="loading-progress-bar" style={{ width: `${loadingProgress}%` }} />
          <div className="loading-message">{`Loading data... (${loadingProgress.toFixed(2)}%)`}</div>
        </div>
      ) : (
        <>
          <h2 className="dashboard-title">Referral Network Activity</h2>
          {chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={400} color="#fff">
              <ComposedChart
                data={chartData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                  color: '#fff',
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis yAxisId="left" label={{ value: 'Number of Users', angle: -90, position: 'insideLeft' }} />
                <YAxis yAxisId="right" orientation="right" label={{ value: 'Total Invest', angle: 90, position: 'insideRight' }} />
                <Tooltip />
                <Legend />
                <Bar yAxisId="left" dataKey="users" fill="#8884d8" name="Number of Users" />
                <Line yAxisId="right" type="monotone" dataKey="totalAmount" stroke="#82ca9d" name="Total Invest" />
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <div className="loading-message">Loading data...</div>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;