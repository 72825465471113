import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/SuperAdminTask.css';

const SuperAdminTask = () => {
    const { web3, contract, account, isAdmin, setIsAdmin } = useContext(WalletContext);
    const [userAddress, setUserAddress] = useState('');
    const [canUpdateOldUsersData, setCanUpdateOldUsersData] = useState(false);
    const [canUpdateLevelsAmount, setCanUpdateLevelsAmount] = useState(false);
    const [canEnableOrDisableCommisionForAll, setCanEnableOrDisableCommisionForAll] = useState(false);
    const [canFreezUser, setCanFreezUser] = useState(false);
    const [canUpdateFees, setCanUpdateFees] = useState(false);
    const [canInvest, setCanInvest] = useState(false);

    const handleUpdateRoll = async () => {
        try {
            await contract.methods.updateRoll(
                userAddress,
                canUpdateOldUsersData,
                canUpdateLevelsAmount,
                canEnableOrDisableCommisionForAll,
                canFreezUser,
                canUpdateFees,
                canInvest,
                isAdmin
            ).send({ from: account });
            alert("User role updated successfully");
        } catch (error) {
            console.error("Error updating user role:", error);
        }
    };

    return (
        <div className="super-admin-task">
            <h2>Update Admin Access</h2>
            <div className="input-group">
                <label>Admin Address:</label>
                <input 
                    type="text" 
                    value={userAddress} 
                    onChange={(e) => setUserAddress(e.target.value)} 
                    placeholder="Enter user address" 
                />
            </div>
            <div className="input-group">
                <label>Can Update Old Users Data:</label>
                <input 
                    type="checkbox" 
                    checked={canUpdateOldUsersData} 
                    onChange={(e) => setCanUpdateOldUsersData(e.target.checked)} 
                />
            </div>
            <div className="input-group">
                <label>Can Update Levels Amount:</label>
                <input 
                    type="checkbox" 
                    checked={canUpdateLevelsAmount} 
                    onChange={(e) => setCanUpdateLevelsAmount(e.target.checked)} 
                />
            </div>
            <div className="input-group">
                <label>Can Enable/Disable Commision For All:</label>
                <input 
                    type="checkbox" 
                    checked={canEnableOrDisableCommisionForAll} 
                    onChange={(e) => setCanEnableOrDisableCommisionForAll(e.target.checked)} 
                />
            </div>
            <div className="input-group">
                <label>Can Freeze User:</label>
                <input 
                    type="checkbox" 
                    checked={canFreezUser} 
                    onChange={(e) => setCanFreezUser(e.target.checked)} 
                />
            </div>
            <div className="input-group">
                <label>Can Update Fees:</label>
                <input 
                    type="checkbox" 
                    checked={canUpdateFees} 
                    onChange={(e) => setCanUpdateFees(e.target.checked)} 
                />
            </div>
            <div className="input-group">
                <label>Can Invest:</label>
                <input 
                    type="checkbox" 
                    checked={canInvest} 
                    onChange={(e) => setCanInvest(e.target.checked)} 
                />
            </div>
            <div className="input-group">
                <label>Is Admin:</label>
                <input 
                    type="checkbox" 
                    checked={isAdmin} 
                    onChange={(e) => setIsAdmin(e.target.checked)} 
                />
            </div>
            <button className='update-roll-btn' onClick={handleUpdateRoll}>Update Role</button>
        </div>
    );
};

export default SuperAdminTask;
