import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/AdminTask.css';

const AdminTask = () => {
    const { web3, contract, stakingContract, account, isAdmin, setIsAdmin, allUsers } = useContext(WalletContext);
    const [selectedAction, setSelectedAction] = useState('');
    const [selectedUser, setSelectedUser] = useState('');

    const handleActionChange = (e) => {
        setSelectedAction(e.target.value);
        setSelectedUser(''); // Reset selected user when action changes
    };

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
    };

    const handleFreezeUser = async () => {
        if (selectedUser) {
            try {
                await contract.methods.freezUser(selectedUser).send({ from: account });
                alert(`User ${selectedUser} has been updated.`);
            } catch (error) {
                console.error("Error freezing/unfreezing user:", error);
                alert("Failed to update the user. See console for details.");
            }
        } else {
            alert("Please select a user.");
        }
    };

    return (
        <div className="admin-task-container">
            <h2>Welcome to Admin control panel</h2>
            
            <div className='select-section'>
            {/* Action Dropdown */}
            <select value={selectedAction} onChange={handleActionChange}>
                <option value="">Select Action</option>
                <option value="freezUser">Freeze/Unfreeze User</option>
            </select>

            {/* Users Dropdown */}
            {selectedAction === 'freezUser' && (
                <div className="user-dropdown">
                    <select value={selectedUser} onChange={handleUserChange}>
                        <option value="">Select User</option>
                        {allUsers.map((user) => (
                            <option key={user} value={user}>
                                {user}
                            </option>
                        ))}
                    </select>

                    <button className='freeze-unfreeze-button' onClick={handleFreezeUser}>Freeze/Unfreeze</button>
                </div>
            )}
            </div>
        </div>
    );
};

export default AdminTask;
