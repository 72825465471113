import React, { useState, useEffect, useRef, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/Sidebar.css';

const Sidebar = ({ onItemClick }) => {
  const { isSuperAdmin, isAdmin } = useContext(WalletContext);
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    onItemClick(item);
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      console.log('Clicked outside sidebar');
      setIsOpen(false);
    } else {
      console.log('Clicked inside sidebar');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sidebarItems = [
    { name: 'Dashboard', icon: 'fas fa-tachometer-alt' },
    { name: 'Transactions', icon: 'fas fa-history' },
    { name: 'Top Sellers', icon: 'fas fa-trophy' },
    { name: 'Referral', icon: 'fas fa-user-plus' },
    { name: 'Seller Application', icon: 'fas fa-store' },
    { name: 'Tasks & Airdrop', icon: 'fas fa-tasks' },
    // { name: 'Add & Edit Admin Task', icon: 'far fa-edit' },
    // { name: 'Admin Task', icon: 'far fa-edit' },
    ...(isSuperAdmin ? [{ name: 'Add & Edit Admin Task', icon: 'far fa-edit' }] : []),
    ...((isAdmin || isSuperAdmin) ? [{ name: 'Admin Task', icon: 'far fa-edit' }] : [])
  ];

  return (
    <>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </button>
      <div ref={sidebarRef} className={`sidebar-container ${isOpen ? '' : 'closed'}`}>
        <div className="sidebar">
          <div className="sidebar-content">
            {sidebarItems.map((item) => (
              <div
                key={item.name}
                className={`sidebar-item ${selectedItem === item.name ? 'active' : ''}`}
                onClick={() => handleItemClick(item.name)}
              >
                <i className={item.icon}></i>
                <span className="item-name">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
