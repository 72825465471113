import React, { useContext, useEffect, useState } from 'react';
import { WalletContext } from '../WalletContext';
import logo from '../assets/MaleLogo.png';
import '../styles/ReferralNode.css';

const ReferralNode = ({ address, level = 0, onExpand, expandedNodes, currentPath }) => {
  const [referrals, setReferrals] = useState([]);
  const [totalEarned, setTotalEarned] = useState(0);
  const { contract, usdtDecimal } = useContext(WalletContext);

  const expanded = expandedNodes.has(address);

  useEffect(() => {
    if (expanded) {
      fetchReferralData();
    }
  }, [expanded, address, contract]);

  const fetchReferralData = async () => {
    try {
      const referralAddresses = await contract.methods.getReferrals(address).call();
      const _totalEarned = await contract.methods.getTotalEarning(address).call();
      const earnedTotal = _totalEarned.toString() / 10 ** usdtDecimal;
      setReferrals(referralAddresses);
      setTotalEarned(earnedTotal.toFixed(2));
    } catch (error) {
      console.error("Error fetching referral data:", error);
    }
  };

  const handleExpand = () => {
    onExpand(address);
  };

  const truncateAddress = (addr) => {
    if (typeof addr !== 'string') return 'Invalid Address';
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  const isInPath = currentPath.includes(address);

  return (
    <div className={`referral-node level-${level}`}>
      <div className="node-content" onClick={handleExpand}>
        <img src={logo} alt="Referee Logo" className="avatar" />
        <div className="node-details">
          <div className="address">{truncateAddress(address)}</div>
          <div>Total Earned: ${totalEarned}</div>
        </div>
      </div>
      {(expanded || isInPath) && referrals.length > 0 && (
        <div className="children">
          {referrals.map((referralAddress, index) => (
            <ReferralNode
              key={index}
              address={referralAddress}
              level={level + 1}
              onExpand={onExpand}
              expandedNodes={expandedNodes}
              currentPath={currentPath}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ReferralTree = () => {
  const { contract } = useContext(WalletContext);
  const [allReferees, setAllReferees] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [currentPath, setCurrentPath] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAllReferees();
  }, [contract]);

  const fetchAllReferees = async () => {
    try {
      setIsLoading(true);
      const refereeAddresses = await contract.methods.getAllReferee().call();
      setAllReferees(refereeAddresses);
    } catch (error) {
      console.error("Error fetching all referees:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpand = (address) => {
    if (currentPath.length === 0) {
      setCurrentPath([address]);
      setExpandedNodes(new Set([address]));
    } else {
      setCurrentPath(prevPath => [...prevPath, address]);
      setExpandedNodes(prevExpanded => new Set([...prevExpanded, address]));
    }
  };

  const handleBack = () => {
    setCurrentPath([]);
    setExpandedNodes(new Set());
  };

  const rootReferee = currentPath.length > 0 ? [currentPath[0]] : allReferees;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (allReferees.length === 0) {
    return (
      <div className="referral-video">
        <iframe width="900" height="482" src="https://www.youtube.com/embed/E2-nmZABnVQ" title="Unlock Rewards with KASU: Turn Connections into Savings!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    );
  }

  return (
    <div className="referral-tree-container">
      {currentPath.length > 0 && (
        <button className="back-button" onClick={handleBack}>
          Back To Home
        </button>
      )}
      {rootReferee.map((address, index) => (
        <ReferralNode
          key={index}
          address={address}
          onExpand={handleExpand}
          expandedNodes={expandedNodes}
          currentPath={currentPath}
        />
      ))}
    </div>
  );
};

export default ReferralTree;